import React, { useContext, useEffect, useState } from 'react'
import { FILE, FILE2, HOME } from '../icons'
import styles from './property.module.scss'
import Fav from './fav'
import Badge from './badge'
import DetailSection from './detailSection'
import Map from './map'
import FeatureSection from './featureSection'
import { Carousel } from 'react-responsive-carousel'
import Calendar from './calendar'
import moment from 'moment'
import { DateAvailableFormat } from '../../constants'
import Agent from './agent'
import { isMobile } from '../../services/auth'
import ReactPlayer from 'react-player'
import Share from './shareAction'
import { useLocation } from '@reach/router'
import { formatAddress, getEvents, getFavState, getSuggestedEvent } from './util'
import userContext from '../../context/userContext'
import BookingModal from './bookingModal'
import AddBooking from './addBooking'
import { getPropertyBookings } from '../../requests/bookingHttp'
import NotificationModal from './notification'
import LoginModal from '../common/loginModal'
import { Link } from 'gatsby'

const thumbsWidth = isMobile() ? 80 : 210

const Details = ({
  id,
  propertyType,
  listingType,
  dateAvailable,
  hidePrice,
  listingPrice,
  monthlyPrice,
  securityBond,
  location,
  agent,
  propertyDetails,
  ecoFeatures,
  healingCooling,
  indoorFeature,
  outdoorFeatures,
  description,
  statementInformation,
  floorPlan,
  video,
  album,
  locationAddress,
  inspectionsTime
}) => {
  const getPrice = () => {
    let price = '$ ' + listingPrice
    if (hidePrice)
      return (
        <a href={`mailto:${agent.email}`} className="text-white">
          {'Contact Agent'.toUpperCase()}
        </a>
      )

    return listingType === 'Leasing' ? price + ' Weekly' : price
  }
  const dateFormatted = moment(dateAvailable).format(DateAvailableFormat)

  const { profile } = useContext(userContext)

  const { href } = useLocation()
  const [fav, setFav] = useState(false)
  const [events, setEvents] = useState([])
  const [bookingDate, setBookingDate] = useState('')
  const [hasBooking, setHasBooking] = useState(false)
  const [suggested, setSuggested] = useState(false)
  const [areEventsFetched, setEventsFetched] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const hasBookingOnCart = events.filter(item => item.id).length > 0

  useEffect(() => {
    const favState = getFavState(profile, id)
    setFav(favState)
  }, [id, profile])

  useEffect(() => {
    const fetchEvents = async () => {
      setEventsFetched(false)
      setSuggested(false)
      const { allBookingCollections } = await getPropertyBookings(id)
      const events = allBookingCollections.map(item => ({ date: moment(item.date).toISOString(), id: null }))
      const userEvents = getEvents(profile, events, id)
      setEvents(userEvents)
      setEventsFetched(true)
    }
    fetchEvents()
  }, [id, profile])

  useEffect(() => {
    const getSuggestedTime = () => {
      if (!suggested && areEventsFetched && profile && !hasBookingOnCart) {
        const suggestedEvent = getSuggestedEvent(events, inspectionsTime, id)
        setInitialDate(suggestedEvent.date)
        setEvents([...events, suggestedEvent])
        setHasBooking(true)
        setSuggested(true)
      }
    }

    // getSuggestedTime()
  }, [events, id, inspectionsTime, suggested, areEventsFetched, profile, hasBookingOnCart])

  return (
    <div className={styles.detail}>
      <div className={styles.detail_topActions}>
        <div className="row">
          <div className="col-5 col-lg-6">
            <span className={styles.detail_type}>{propertyType}</span>
          </div>
          <div className="col col-lg-3 d-none d-lg-flex align-items-center justify-content-end">
            <span className={`${styles.detail_subtitle} mt-lg-0 mr-lg-2`}>Date Available</span>
            <span className="font-weight-bold small">{dateFormatted}</span>
          </div>
          <div className="col-7 col-lg-3 d-flex justify-content-between align-self-center">
            <Fav fav={fav} propertyId={id} setFav={setFav} />
            <Share url={href} />
            <Badge parent="detail">
              <HOME /> {listingType}
            </Badge>
          </div>
        </div>
      </div>
      <div className={`${styles.detail_listingDescription} d-lg-none`}>{description}</div>
      <div className={styles.divider}></div>
      <div className="mb-3 d-lg-none">
        <span className={styles.detail_subtitle}>Date Available</span>{' '}
        <span className="font-weight-bold small">{dateFormatted}</span>
      </div>
      <div>
        <Carousel thumbWidth={thumbsWidth}>
          {album.map((image, index) => {
            return (
              <div key={index} className={styles.thumbnail}>
                <img src={image.responsiveImage.src} alt={image.filename} className="img-fluid d-block" />
              </div>
            )
          })}
        </Carousel>
      </div>
      <div className="row">
        <div className="col col-lg-8 d-none d-lg-block">
          <h3 className={styles.detail_subtitle}>Property Description</h3>
          <div className={`${styles.detail_listingDescription} d-none d-lg-block`}>{description}</div>
        </div>
        <div className="col col-lg-4">
          <div>
            <h3 className={styles.detail_subtitle}>Property Details</h3>
            <DetailSection detail={propertyDetails} />
          </div>
        </div>
      </div>

      <div>
        <h3 className={styles.detail_subtitle}>Property Features</h3>
        <FeatureSection
          ecoFeatures={ecoFeatures}
          healingCooling={healingCooling}
          indoorFeature={indoorFeature}
          outdoorFeatures={outdoorFeatures}
        />
      </div>
      <div className="d-sm-none">
        <Link to="#calendar" className="btn btn-block btn-primary mb-3">
          Go to Calendar
        </Link>
      </div>
      <div className="row align-items-stretch">
        <div className={`col-12 col-lg-3 ${video ? `col-lg-3 order-lg-1 pl-lg-0` : `col-lg-12`}`}>
          <div>
            <div className="row align-items-center mb-3">
              <div className="col-6 pr-0 col-lg-12">
                <h3 className={styles.detail_subtitle}>PROPERTY {listingType === 'Leasing' && 'LEASING'} PRICE</h3>
              </div>
              <div className="col-6 pl-0 pl-lg-3 col-lg-12">
                <div className="text-right text-lg-left">
                  <div className="mb-3">
                    <span className="bg-primary p-2 rounded text-white d-inline-block">{getPrice()}</span>
                  </div>
                  <div>
                    {monthlyPrice && (
                      <span className="bg-primary p-2 rounded text-white d-inline-block">$ {monthlyPrice} Monthly</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {listingType === 'Leasing' && (
            <div>
              <div className="row align-items-center mb-3">
                <div className="col-6 pr-0 col-lg-12">
                  <h3 className={styles.detail_subtitle}>Security Bond</h3>
                </div>
                <div className="col-6 pl-0 pl-lg-3 col-lg-12">
                  <div className="text-right text-lg-left">
                    <span className="bg-primary p-2 rounded text-white d-inline-block">$ {securityBond}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div>
            <Agent agent={agent} />
          </div>
          {floorPlan && (
            <div className="mb-3">
              <h3 className={styles.detail_subtitle}>
                <a href={floorPlan.url} title="Statement information" target="_blank" rel="noreferrer">
                  <FILE2 /> Floor Plan
                </a>
              </h3>
            </div>
          )}
          {statementInformation && (
            <div>
              <h3 className={styles.detail_subtitle}>
                <a href={statementInformation.url} title="Statement information" target="_blank" rel="noreferrer">
                  <FILE /> View Statement of Information
                </a>
              </h3>
            </div>
          )}
        </div>
        <div className={`col-12 col-lg-9 ${video ? `col-lg-9 order-lg-0` : `col-lg-12`}`}>
          {video && (
            <div className="mb-3">
              <h3 className={`${styles.detail_subtitle} d-lg-none`}>Video Tour</h3>

              <div className="player-wrapper">
                <ReactPlayer url={video.url} width="100%" className="react-player" height="100%" />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mb-3">
        <h3 className={styles.detail_subtitle}>Location map of the property</h3>
        <p>{formatAddress(locationAddress[0])}</p>
        <Map lat={location.latitude} lng={location.longitude} />
      </div>
      <div className="mb-3">
        <h4 className="font-weight-bold mb-4">When would you like to visit?</h4>
        <p className="text-center font-weight-bold">Click on a white empty slot to book your inspection</p>
        <div className="row">
          <div className="col-12 col-lg-10 mx-auto" id="calendar">
            <Calendar
              events={events}
              times={inspectionsTime}
              setBookingDate={setBookingDate}
              initialDate={initialDate}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="text-center">
          {hasBooking && (
            <AddBooking
              locationAddress={locationAddress}
              setHasBooking={setHasBooking}
              events={events}
              propertyId={id}
              propertyImageURL={album[0].responsiveImage.src}
            />
          )}
        </div>
      </div>

      <BookingModal
        bookingDate={bookingDate}
        events={events}
        times={inspectionsTime}
        addEvent={setEvents}
        setHasBooking={setHasBooking}
        propertyId={id}
      />
      <NotificationModal />
      <LoginModal />
    </div>
  )
}
export default Details
