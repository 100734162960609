import React from 'react'
import { Link } from 'gatsby'
import { LOGIN } from '../../routes'

const LoginModal = () => {
  return (
    <div
      className="modal fade"
      id="loginModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Log in or Create an account in order to schedule a booking
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Create an account
            </button>
            <Link className="btn btn-primary" to={LOGIN}>
              Go to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginModal
