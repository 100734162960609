import React from 'react'
import { RIGHT_ARROW } from '../icons'
import styles from './checkout.module.scss'

const Action = ({ disabled, amount, handleAction }) => (
  <button
    type="button"
    disabled={disabled}
    className={`btn btn-primary btn-block ${styles.action}`}
    onClick={() => handleAction()}
  >
    <span className={styles.action_amount}>$ {amount}</span>
    <span className={styles.action_text}>
      Checkout <RIGHT_ARROW />
    </span>
  </button>
)
export default Action
