import React from 'react'
import SearchForm from '../dashboard/searchForm'
import styles from '../../pages/index.module.scss'
import List from './list'
import Data from '../common/data'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { getProperties } from '../../requests/propertyHTTP'

const Results = () => {
  const query = queryString.parse(useLocation().search)
  const error = {
    message: 'No results found'
  }
  const result = {
    properties: []
  }
  const ShortListWithData = Data(List, { error, result }, () => getProperties(query))

  return (
    <div className="pt-lg-5">
      <div className="mb-5">
        <SearchForm>
          <h4 className={styles.search_title}>Let’s find your perfect home</h4>
        </SearchForm>
      </div>

      <h2 className="h1 font-weight-bold mb-4">Top search results for you</h2>
      <ShortListWithData />
    </div>
  )
}
export default Results
