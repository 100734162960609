const moment = require('moment-timezone')
const { checkout } = require('./buyerHttp')
const { getItemType } = require('./util')
const { CHUNK_SIZE } = require('../constants')
const { skips } = require('../constants')
const { client } = require('./index')
const { request } = require('./index')
const { BOOKING_TYPE } = require('./modelsID')

/**
 * @typedef booking
 * @type {Object}
 * @property {string} propertyId - Property's id
 * @property {string} date - Booking's date
 * @property {string} address - Property's address
 * @property {string} slug - Property's slug
 * @property {string} image - Property's portrait photo url
 */

const isBookingCreated = async (propertyID, bookingDate) => {
  const query = `
    query Bookings ($propertyID: ItemId!, $bookingDate: DateTime) {
  bookings: allBookingCollections (filter: { property: {eq : $propertyID} date: {eq: $bookingDate}}) {
    property {
      slug
      title
    }
    buyer {
      fullName
    }
    date
    lockboxCode
  }
}
    `
  return request({ query, variables: { propertyID, bookingDate } })
}

/**
 * Create a new booking record for each booking in the cart
 * @param {string} id - Customer id
 * @param {booking[]}  properties - Customer's booking
 * @return {Promise<void>}
 */
module.exports.processCheckout = async (id, properties) => {
  for (const item of properties) {
    const { bookings } = await isBookingCreated(item.propertyId, item.date)
    if (bookings.length === 0) {
      const melbourneDate = moment(item.date).tz('Australia/Melbourne').format()
      await createBooking({ property: item.propertyId, buyer: id, date: melbourneDate })
    }
  }
  await checkout(id)
}

/**
 * Creates a new Booking record on DatoCMS
 * @param {Object} booking - Booking object
 * @param {string} booking.property - Property's id
 * @param {string} booking.buyer - Buyer's id
 * @param {string | Date} booking.date - Booking's date
 * @return {Object} - Booking Record
 */
const createBooking = async booking => {
  let item = { ...booking }
  item.agent = null
  item.lockboxCode = null
  const { id } = await getItemType(BOOKING_TYPE)
  item.itemType = id
  return client.items.create(item)
}

module.exports.createBooking = createBooking
/**
 * Updates the lockBox Code from the booking record on DatoCMS
 * @param {string} id - Booking's id
 * @param {string} code - LockBox code
 * @param {Object} creator - Creator Object
 * @param {string} creator.id - Creator's id
 * @param {string} creator.type - Creator's type
 * @return {Object} - Booking Record
 */
module.exports.updateLockBoxByBookingId = function (id, code, creator) {
  return client.items.update(id, {
    lockboxCode: code,
    creator: creator
  })
}

/**
 * Gets the list of bookings on DatoCMS that matches with any of the dates and properties id within the Buyer's cart
 * @param {booking[]} cart - Buyer's cart
 * @return {Promise<{Properties: Object[]}>}
 */
module.exports.verifyProperties = cart => {
  const propertiesIds = cart.map(item => item.propertyId)
  const propertiesDates = cart.map(item => item.date)
  let Filter = `$properties: [ItemId] $OR: [BookingCollectionModelFilter] = [`
  propertiesDates.forEach(date => {
    Filter += `{date: {eq: "${date}"}}`
  })
  Filter += `]`
  let Query = `
    query BookingQuery (${Filter}) {
      Properties: allBookingCollections (filter: {property: {in: $properties} OR: $OR}) {
        id
        property {
          id
          slug
        }
        date
      }
    }
  `
  return request({ query: Query, variables: { properties: propertiesIds } })
}

/**
 * Gets the bookings made to a property on DatoCMS
 * @param {string} propertyId - Property's id
 * @return {Promise<{allBookingCollections: Object}>}
 */
module.exports.getPropertyBookings = propertyId => {
  const query = `
    query bookingsQuery($propertyId: ItemId) {
      allBookingCollections(filter: {property: {eq: $propertyId}}) {
        date
      }
    }
  `
  return request({ query, variables: { propertyId } })
}

/**
 * Gets the bookings made by a Buyer from DatoCMS
 * @param {string} buyerId - Buyer's id
 * @param {string | number} page - The index of the next chunk to show
 * @return {Promise<{allPropertyCollections: Object[], _allPropertyCollectionsMeta: Object}>}
 */
module.exports.getBuyerBookings = ({ buyerId, page = 1 }) => {
  const skip = skips(page)
  const BuyerBookings = `
  query buyerBookingsQuery($buyerId: ItemId) {
    allPropertyCollections: allBookingCollections(first: ${CHUNK_SIZE}, skip: ${skip}, filter: {buyer: {eq: $buyerId}}, orderBy: date_DESC) {
      property {
        slug
        locationAddress {
          addressLine2
          suburb
          number
          postCode
          street
        }
        bathrooms
        bedrooms
        toilets
        agent {
          email
          name
          phoneNumber
        }
        gallerySection {
          portraitPhoto {
            alt
            filename
            responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 450, h: 172}) {
              src
            }
          }
        }
      }
      date
      lockboxCode
    }
    _allPropertyCollectionsMeta: _allBookingCollectionsMeta(filter:{buyer: {eq: $buyerId}}) {
      count
    }
  }
`
  return request({ query: BuyerBookings, variables: { buyerId } })
}
/**
 * Gets all the bookings from DatoCMS made at a date range
 * @param {string} startDate - Start date to filter bookings
 * @param {string} endDate - End date to filter bookings
 * @return {Promise<{allBookingCollections: Object}>}
 */
module.exports.getBookingsToday = (startDate, endDate) => {
  const query = `
    query bookingsQuery ($startDate: DateTime!, $endDate: DateTime!) {
      allBookingCollections(filter: {date: {lt: $endDate, gte: $startDate} lockboxCode:{neq: null}}, orderBy: date_DESC) {
        property {
          slug
          title
          lockBoxLocator
          lockBoxLocation {
            alt
            filename
            responsiveImage(imgixParams: {fm: jpg, fit: scale, w: 450, h: 225}) {
              src
            }
          }
          locationAddress {
            addressLine2
            suburb
            number
            postCode
            street
          }
          gallerySection {
            portraitPhoto {
              alt
              filename
              responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 450, h: 172}) {
                src
              }
            }
          }
        }
        buyer {
          fullName
          email
        }
        date
        lockboxCode
      }
    }
  `
  return request({ query, variables: { startDate, endDate } })
}
