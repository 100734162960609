import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { getUser } from '../../services/auth'
import styles from './checkout.module.scss'
import { Link } from 'gatsby'
import userContext from '../../context/userContext'
import { getWallet } from '../../requests/paymentHttp'
import { TERMS } from '../../routes'

const SplitForm = ({ handleCard, hideStripeForm }) => {
  const { profile, setProfile } = useContext(userContext)
  const stripe = useStripe()
  const elements = useElements()
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#495057',
          '::placeholder': {
            color: '#6c757d'
          }
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    []
  )
  const [name, setName] = useState(getUser().fullName)
  const [error, setError] = useState('')
  const [intent, setIntent] = useState('')

  const clearValues = () => {
    elements.getElement(CardNumberElement).clear()
    elements.getElement(CardExpiryElement).clear()
    elements.getElement(CardCvcElement).clear()
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements || !intent) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    const result = await stripe.confirmCardSetup(intent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name
        }
      }
    })
    if (result.error) {
      console.error('[error]', result.error)
      setError(result.error.message)
    } else {
      handleCard()
      clearValues()
      setError('')
    }
  }

  useEffect(() => {
    const fetchWallet = async () => {
      if (!intent) {
        const {
          data: { clientSecret, customerId }
        } = await getWallet(profile.id)
        setIntent(clientSecret)
        if (!profile.customerId) {
          setProfile(profile => ({ ...profile, customerId }))
        }
      }
    }
    fetchWallet()
  }, [profile, intent, setProfile])

  return (
    <div className="mb-5 mb-lg-0">
      <p className="font-weight-bold d-lg-none">Card Details</p>
      <p className={`small font-weight-light ${styles.stripe_terms}`}>
        By continuing you agree to our <Link to={TERMS}>Terms</Link>
      </p>
      <div className={`${styles.divider_gray} d-none d-lg-block`} />
      <form onSubmit={handleSubmit} className={styles.stripe_form}>
        <div className="form-group">
          <label htmlFor="name">Name on Card</label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={e => setName(e.target.value)}
            required
            className="form-control"
            placeholder="Wayne Rooney"
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Card number</label>
          <CardNumberElement id={'number'} className="form-control" options={options} />
        </div>

        <div className="form-row">
          <div className="col-6 form-group">
            <label htmlFor="expiration">Expiration date</label>
            <CardExpiryElement id={'expiration'} className="form-control" options={options} />
          </div>
          <div className="col-6 form-group">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement id={'cvv'} className="form-control" options={options} />
          </div>
        </div>
        <div className="mb-5">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <button type="submit" className={`${styles.stripe_btn_add} btn btn-primary mb-4`} disabled={!stripe || !intent}>
          Add card
        </button>
        <button
          type="button"
          className={`${styles.stripe_btn_cancel} btn btn-outline-primary`}
          onClick={() => hideStripeForm(false)}
        >
          Cancel
        </button>
      </form>
    </div>
  )
}

export default SplitForm
