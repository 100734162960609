import React, { useCallback, useContext, useEffect, useState } from 'react'
import Form from './form'
import userContext from '../../context/userContext'
import useFirebase from '../../hooks/useFirebase'
import { forceMFA, updateBuyer } from '../../requests/buyerHttp'
import ReAuthenticate from './reAuthenticate'
import $ from 'jquery'
import { isBrowser } from '../../services/auth'
import { captchaVerifier, sendVerificationCode, verifyCode } from '../../services/firebaseAuth'
import MFAVerificationCode from './mfaVerificationModal'

const ACTIONS = {
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  ENABLE_MFA: 'ENABLE_MFA',
  DISABLE_MFA: 'DISABLE_MFA'
}

const handleProfile = async (currentUser, account) => {
  await currentUser.updateProfile({
    displayName: account.fullName
  })
  await updateAccount(account)
}

const handleEmailUpdate = async (currentUser, account) => {
  await currentUser.sendEmailVerification()
  return await updateAccount(account)
}
const updateAccount = async account => {
  return await updateBuyer(account)
}

const Account = () => {
  const { profile, setProfile } = useContext(userContext)
  const app = useFirebase()
  const [account, setAccount] = useState(profile)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [captcha, setCaptcha] = useState(null)
  const [verificationId, setVerificationId] = useState('')
  const [isReAuth, setIsReAuth] = useState(false)
  const [isMFA, setIsMFA] = useState(false)
  const [mfaError, setMFAError] = useState('')
  const [action, setAction] = useState('')
  const [resolver, setResolver] = useState(null)
  const currentUser = app.auth().currentUser
  const isEmailVerified = currentUser ? currentUser.emailVerified : true

  const handleProfileValue = e => {
    const value = e.target.value
    const name = e.target.name
    setAccount({ ...account, [name]: value })
  }

  const handleUpdate = async e => {
    e.preventDefault()
    setSuccess('')
    setError('')
    if (account.email !== profile.email) {
      setAction(ACTIONS.UPDATE_EMAIL)
      $('#reAuthenticateModal').modal('show')
    } else if (account.phoneNumber !== profile.phoneNumber && profile.mfa) {
      //todo: verify phone number
    } else {
      try {
        await handleProfile(currentUser, account)
      } catch (e) {
        setError(e.message)
      }
    }
  }

  const sync = useCallback(
    user => {
      setAction('')
      setProfile(user)
      setAccount(user)
      setIsReAuth(false)
      setIsMFA(false)
    },
    [setProfile]
  )

  const handleMFA = () => {
    const action = account.mfa ? ACTIONS.DISABLE_MFA : ACTIONS.ENABLE_MFA
    triggerReAuth(action)
  }

  const triggerReAuth = action => {
    setAction(action)
    $('#reAuthenticateModal').modal('show')
  }

  const handleDisableMFA = useCallback(async () => {
    const options = currentUser.multiFactor.enrolledFactors
    await currentUser.multiFactor.unenroll(options[0])
    const user = await forceMFA(profile.id, false)
    sync(user)
    setSuccess('Successfully unenrolled')
  }, [currentUser, profile.id, sync])

  const handleMFAAuth = useCallback(
    async phoneInfoOptions => {
      const verificationId = await sendVerificationCode(phoneInfoOptions, captcha)
      setIsMFA(false)
      setVerificationId(verificationId)
      $('#mfaVerificationCode').modal('show')
    },
    [setVerificationId, captcha]
  )

  const handleVerificationCode = async code => {
    try {
      const multiFactorAssertion = await verifyCode(verificationId, code, captcha)
      if (!profile.mfa) {
        await currentUser.multiFactor.enroll(multiFactorAssertion, 'My personal phone number')
        const user = await updateAccount({ ...account, mfa: true })
        sync(user)
        setSuccess('Your phone has been enrolled with your account')
      } else {
        await resolver.resolveSignIn(multiFactorAssertion)
        setIsMFA(false)
        setIsReAuth(true)
      }
      setVerificationId('')
      $('#mfaVerificationCode').modal('hide')
    } catch (e) {
      setError(e.message)
      setMFAError(e.message)
      const widgetId = await captcha.render()
      window.grecaptcha.reset(widgetId)
    }
  }

  const sendVerificationEmail = async () => {
    try {
      await currentUser.sendEmailVerification()
      setSuccess('Verification email sent')
    } catch (e) {
      setError(e.message)
    }
  }

  useEffect(() => {
    if (isBrowser() && app && !captcha) {
      setCaptcha(captchaVerifier('recaptcha-container'))
    }
  }, [app, captcha])

  useEffect(() => {
    const reAuthCB = async () => {
      if (isMFA && !isReAuth && isBrowser() && resolver) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        }
        await handleMFAAuth(phoneInfoOptions)
      }
      if (isBrowser() && isReAuth) {
        try {
          if (action === ACTIONS.ENABLE_MFA && !verificationId) {
            const { phoneNumber } = account
            const multiFactorSession = await currentUser.multiFactor.getSession()
            const phoneInfoOptions = {
              phoneNumber: phoneNumber,
              session: multiFactorSession
            }
            await handleMFAAuth(phoneInfoOptions)
          } else if (action === ACTIONS.UPDATE_EMAIL && !profile.mfa && !isMFA) {
            const user = await handleEmailUpdate(currentUser, account)
            sync(user)
            setSuccess('Account updated successfully')
          } else if (action === ACTIONS.DISABLE_MFA) {
            await handleDisableMFA()
          }
        } catch (e) {
          setError(e.message)
        }
      }
    }
    reAuthCB()
  }, [
    isReAuth,
    verificationId,
    handleMFAAuth,
    currentUser,
    account,
    action,
    isMFA,
    handleDisableMFA,
    sync,
    resolver,
    profile.mfa
  ])

  return (
    <div>
      <div className="text-lg-center">
        <h1 className="font-weight-bold mb-lg-3">Hey, {profile.fullName}</h1>
        <p className="text-gray mb-5">Have things changed? It’s easy to update your details below.</p>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-lg-6 mx-auto">
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success mt-3" role="alert">
              {success}
            </div>
          )}
          <Form
            profile={account}
            handleProfileValue={handleProfileValue}
            handleFormSubmit={handleUpdate}
            handleEnableMFA={handleMFA}
            verified={isEmailVerified}
            handleSendVerificationEmail={sendVerificationEmail}
          />
        </div>
      </div>
      <ReAuthenticate setIsReAuth={setIsReAuth} setResolver={setResolver} setIsMFA={setIsMFA} />
      <MFAVerificationCode handleVerificationCode={handleVerificationCode} error={mfaError} />
      <div id="recaptcha-container"></div>
    </div>
  )
}

export default Account
