import React, { useContext } from 'react'
import List from './list'
import Data from '../common/data'
import userContext from '../../context/userContext'
import { getShortlist } from '../../requests/propertyHTTP'
import queryString from 'query-string'
import { useLocation } from '@reach/router'

const Shortlist = () => {
  const query = queryString.parse(useLocation().search)
  const { profile } = useContext(userContext)
  const { shortlist } = profile
  const error = {
    cta: 'Find my dream home',
    message:
      'You haven’t added any homes to your shortlist yet. Search our listings and click the heart symbol to save your favourite homes to your shortlist.'
  }
  const result = {
    properties: []
  }

  const ShortListWithData = Data(List, { error, result }, () => getShortlist({ shortlist, page: query.page }), true)

  return (
    <div>
      <h1 className="font-weight-bold mb-4">Your shortlisted homes</h1>
      <ShortListWithData />
    </div>
  )
}

export default Shortlist
