import React, { useCallback, useEffect, useState } from 'react'
import { isBrowser } from '../../services/auth'

const DigitalIdButton = ({ handleVerification }) => {
  const [instance, setInstance] = useState(false)

  const verify = useCallback(value => handleVerification(value), [handleVerification])

  useEffect(() => {
    const clientID = process.env.GATSBY_DIGITAL_ID_CLIENT_ID

    /* Verify with Digital iD */
    if (isBrowser() && !instance) {
      window.digitalId.init({
        clientId: clientID,
        uxMode: 'popup',
        onLoadComplete: function () {},
        onComplete: function (response) {
          if (!response.error) verify(response.code)
        },
        onClick: function () {},
        onKeepAlive: function () {}
      })
      setInstance(true)
    }
  }, [instance, verify])
  return <div id="digitalid-verify" />
}

export default DigitalIdButton
