import styles from './property.module.scss'
import Summary from './summary'
import { BATH, BED, ENSUITE, HOME, LIVING, MAP, TOILET, TREE } from '../icons'
import React from 'react'

const DetailSection = ({
  detail: { bedrooms, bathrooms, ensuites, toilets, livingAreas, outdoorArea, houseSize, landSize }
}) => (
  <div className="d-flex flex-wrap">
    <div className={styles.detail_item}>
      <Summary>
        <BED /> x{bedrooms} Bedrooms
      </Summary>
    </div>
    <div className={styles.detail_item}>
      <Summary>
        <TREE /> Outdoor {outdoorArea}
      </Summary>
    </div>
    <div className={styles.detail_item}>
      <Summary>
        <BATH /> x{bathrooms} Bathrooms
      </Summary>
    </div>
    {houseSize && (
      <div className={styles.detail_item}>
        <Summary>
          <HOME /> House Size {houseSize} m<sup>2</sup>
        </Summary>
      </div>
    )}
    <div className={styles.detail_item}>
      <Summary>
        <TOILET /> x{toilets} Toilets
      </Summary>
    </div>
    {landSize && (
      <div className={styles.detail_item}>
        <Summary>
          <MAP /> Land Size {landSize} m<sup>2</sup>
        </Summary>
      </div>
    )}
    {ensuites && (
      <div className={styles.detail_item}>
        <Summary>
          <ENSUITE /> x{ensuites} Ensuites
        </Summary>
      </div>
    )}
    {livingAreas && (
      <div className={styles.detail_item}>
        <Summary>
          <LIVING /> Living Areas x{livingAreas}
        </Summary>
      </div>
    )}
  </div>
)

export default DetailSection
