import React, { useContext } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { isBrowser } from '../../services/auth'
import { Redirect, useLocation } from '@reach/router'
import userContext from '../../context/userContext'
import { VERIFICATION } from '../../routes'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise
if (isBrowser()) {
  stripePromise = loadStripe(process.env.GATSBY_STRIPE)
}

const StripeWrapper = WrappedComponent => {
  const WithStripe = () => {
    const { profile } = useContext(userContext)
    const location = useLocation()
    const { state = {} } = location
    const { fromVerificationPage } = state ? state : false
    const redirectToVerificationPage = !fromVerificationPage && !profile.digitalId && profile.cart.length === 0

    if (redirectToVerificationPage) return <Redirect to={VERIFICATION} noThrow />
    return (
      <Elements stripe={stripePromise}>
        <WrappedComponent />
      </Elements>
    )
  }

  return WithStripe
}

export default StripeWrapper
