import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { isLoggedIn, isMobile } from '../../services/auth'
import { getCalendarOpenHours, isDayAvailable } from './util'
import $ from 'jquery'
import moment from 'moment'

const headerToolbar = {
  left: 'prev today',
  center: 'title',
  right: 'next'
}
const titleFormat = isMobile() ? { year: 'numeric', month: 'long' } : { year: 'numeric', month: 'long', day: 'numeric' }
const height = isMobile() ? 500 : 700
const validRange = nowDate => {
  return {
    start: nowDate
  }
}

const initialView = isMobile() ? 'timeGridDay' : 'timeGridWeek'

const Calendar = ({ events, times, setBookingDate, initialDate }) => {
  const defaultColor = '#3788d8'
  const personalColor = '#86d015'
  const calendarEvents = events.map(item => {
    return {
      title: item.id ? 'Pending checkout' : `Too late I'm taken`,
      date: moment(item.date).format(),
      backgroundColor: item.id ? personalColor : defaultColor
    }
  })
  const handleDateClick = arg => {
    // bind with an arrow function
    if (isDayAvailable(times, arg.dateStr) && isLoggedIn()) {
      setBookingDate(moment(arg.dateStr).toISOString())
      $('#bookingModal').modal('show')
    } else if (!isLoggedIn()) {
      $('#loginModal').modal('show')
    }
  }

  return (
    <FullCalendar
      timeZone="Australia/Melbourne"
      themeSystem="bootstrap"
      headerToolbar={headerToolbar}
      titleFormat={titleFormat}
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView={initialView}
      dateClick={handleDateClick}
      events={calendarEvents}
      allDaySlot={false}
      slotMinTime={'06:00:00'}
      slotMaxTime={'23:00:00'}
      dayHeaderFormat={{ weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }}
      nowIndicator={true}
      validRange={validRange}
      businessHours={getCalendarOpenHours(times)}
      slotDuration={'01:00:00'}
      expandRows={true}
      height={height}
      initialDate={initialDate}
    />
  )
}

export default Calendar
