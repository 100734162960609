import React, { useContext, useEffect, useState } from 'react'
import VerifyCard from '../verify/card'
import NoPayment from './NoPayment'
import Action from './action'
import PropertyCard from './card'
import List from './list'
import { navigate } from 'gatsby'
import { THANKS } from '../../routes'
import styles from './checkout.module.scss'
import ThanksCard from '../thanks/card'
import Data from '../common/data'
import { isMobile } from '../../services/auth'
import DigitalIdButton from './digitalID'
import userContext from '../../context/userContext'
import { addToCart, getUserByEmail } from '../../requests/buyerHttp'
import StripeWrapper from './stripeWrapper'
import SplitForm from './stripeElements'
import { useStripe } from '@stripe/react-stripe-js'
import { getCards, getCartPayment, sendCheckoutEmail, verifyBuyer } from '../../requests/paymentHttp'
import { processCheckout, verifyProperties } from '../../requests/bookingHttp'
import useSettings from '../../hooks/useSettings'
import axios from 'axios'
import { isAFutureDate } from '../property/util'

const toggleInfo = isCardVisible => {
  if (isMobile()) {
    return !isCardVisible ? 'd-block' : 'd-none'
  } else {
    return 'd-block'
  }
}

const toggleCard = isCardVisible => {
  if (isMobile()) return toggleInfo(isCardVisible)
  else {
    return !isCardVisible ? 'd-block' : 'd-none'
  }
}

const Checkout = () => {
  const { bookingCost, chargeVerification, chargeBooking, verificationCost } = useSettings()
  const { profile, setProfile } = useContext(userContext)
  const [cards, setCards] = useState([])
  const [isStripeFormVisible, setStripeFormVisible] = useState(false)
  const [cart, setCart] = useState({ properties: [], email: '' })
  const [recentVerified, setRecentVerified] = useState(false)
  const [cardIndex, setCardIndex] = useState(0)
  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const [bookingAvailable, setBookingAvailable] = useState(false)
  const [isCartVerified, setCartVerified] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isCustomerFetched, setCustomerFetched] = useState(false)
  const [amount, setAmount] = useState(0)

  //Stripe payment intents
  const [clientSecret, setClientSecret] = useState('')
  const stripe = useStripe()

  let hasCards, disabled

  hasCards = cards.length > 0
  disabled = !chargeBooking
    ? !(profile.verified && bookingAvailable && hasCards && !isCheckingOut && isCustomerFetched)
    : !(hasCards && profile.digitalId && !isCheckingOut && bookingAvailable && isCustomerFetched)
  const showCheckoutButton = !chargeBooking ? hasCards : hasCards && clientSecret

  const getVerificationComponent = () => {
    if (profile.verified) {
      if (recentVerified) {
        return <ThanksCard />
      } else {
        return null
      }
    } else {
      return <VerifyCard verificationCost={verificationCost} chargeVerification={chargeVerification} />
    }
  }

  const handleStripeForm = (show = true) => {
    setStripeFormVisible(show)
  }

  const handleAddCard = async () => {
    try {
      setErrorMsg('')
      if (profile.customerId) {
        const { data } = await getCards(profile.customerId)
        setCards(data)
      }
      setStripeFormVisible(false)
    } catch (e) {
      setErrorMsg(e.message)
      console.error(e.message)
    }
  }

  const handleBookRemove = async propertyId => {
    try {
      setErrorMsg('')
      const newCartProperties = cart.properties.filter(item => item.propertyId !== propertyId)
      await addToCart(profile.id, JSON.stringify(newCartProperties))
      setProfile({ ...profile, cart: newCartProperties })
      setCart({ ...cart, properties: newCartProperties })
    } catch (e) {
      setErrorMsg(e.message)
      console.error(e.message)
    }
  }

  const handleVerification = async code => {
    if (!recentVerified) {
      try {
        setErrorMsg('')
        const {
          data: { digitalId, verified }
        } = await verifyBuyer(profile.id, code)
        setProfile(profile => ({ ...profile, digitalId, verified }))
        setRecentVerified(verified)
      } catch (e) {
        setErrorMsg(e.message)
        console.error(e.message)
      }
    }
  }

  const navigateToThanksPage = last4 => {
    setProfile({ ...profile, cart: [] })
    navigate(THANKS, { state: { fromCheckout: true, card: last4 } })
  }

  const handlePayedCheckout = async () => {
    const {
      id,
      card: { last4 }
    } = cards[cardIndex]
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: id
    })
    if (payload.error) {
      setErrorMsg(payload.error.message)
      console.error(payload.error)
    } else if (payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
      navigateToThanksPage(last4)
    }
  }

  const handleFreeCheckout = async () => {
    await processCheckout(profile.id, profile.cart)
    sendCheckoutEmail(profile.email, profile.fullName, profile.cart)
    navigateToThanksPage()
  }

  const handleCheckout = async () => {
    if (profile.digitalId) {
      try {
        setErrorMsg('')
        setIsCheckingOut(true)
        if (chargeBooking) {
          await handlePayedCheckout()
        } else {
          await handleFreeCheckout()
        }
        setIsCheckingOut(false)
      } catch (e) {
        setErrorMsg(e.message)
        console.error(e.message)
        setIsCheckingOut(false)
      }
    }
  }

  //Gets the customer data from CMS
  useEffect(() => {
    const fetchCustomer = async () => {
      if (!isCustomerFetched && bookingCost) {
        try {
          setErrorMsg('')
          const { buyerCollection: customer } = await getUserByEmail(profile.email)
          const cart = customer.cart.map(item => {
            item.inspectionPrice = chargeBooking ? bookingCost : 0
            if (!isAFutureDate(item.date)) item.taken = true
            return item
          })
          setCustomerFetched(true)
          setProfile({ ...customer, shortlist: customer.shortlist.map(item => item.id) })
          setCart({ properties: cart, email: customer.email })
        } catch (e) {
          setErrorMsg(e.message)
        }
      }
    }
    fetchCustomer()
  }, [isCustomerFetched, setProfile, profile.email, bookingCost, chargeBooking])

  //Gets the customer credit cards
  useEffect(() => {
    const fetchCards = async () => {
      try {
        if (profile.customerId && isCustomerFetched) {
          setErrorMsg('')
          const { data } = await getCards(profile.customerId)
          setCards(data)
        }
      } catch (e) {
        setErrorMsg(`${e.message}. Please reload the page`)
      }
    }
    fetchCards()
  }, [profile.customerId, isCustomerFetched])

  //Gets the customer payment intent based on the cart
  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const fetchCartIntent = async () => {
      try {
        if (profile.digitalId && profile.customerId && isCustomerFetched && bookingAvailable && chargeBooking) {
          setErrorMsg('')
          const { data } = await getCartPayment(profile.id, source.token)
          setProfile(profile => ({ ...profile, paymentIntent: data.id }))
          setClientSecret(data.clientSecret)
          setAmount(data.amount)
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          setErrorMsg(`${e.message}. Please reload the page`)
        } else {
          console.error(e.message)
        }
      }
    }
    fetchCartIntent()
    return () => source.cancel('Operation canceled')
  }, [
    profile.digitalId,
    cart,
    profile.customerId,
    profile.id,
    setProfile,
    isCustomerFetched,
    bookingAvailable,
    chargeBooking
  ])

  //Gets Availability to checkout bookings
  useEffect(() => {
    const fetchCartAvailability = async () => {
      if (!isCartVerified && isCustomerFetched) {
        try {
          setErrorMsg('')
          let cartProperties = [...cart.properties]
          const { Properties } = await verifyProperties(cart.properties)
          setCartVerified(true)

          const propertiesId = Properties.map(item => item.property.id)
          propertiesId.forEach(id => {
            const targetIndex = cartProperties.findIndex(item => item.propertyId === id)
            if (targetIndex > -1) {
              cartProperties[targetIndex].taken = true
            }
          })
          if (propertiesId.length > 0) setCart({ ...cart, properties: cartProperties })
        } catch (e) {
          setErrorMsg(e.message)
        }
      }
    }
    fetchCartAvailability()
  }, [cart, isCartVerified, isCustomerFetched])

  //Enables checkout if bookings are available for the date specified
  useEffect(() => {
    if (isCartVerified) {
      const cartItemsAlreadyBooked = cart.properties.filter(item => item.taken === true)
      setBookingAvailable(cartItemsAlreadyBooked.length < 1)
    }
  }, [cart, isCartVerified])

  const PropertiesCards = () => (
    <div>
      {cart.properties.map((property, index) => (
        <PropertyCard {...property} handleRemove={handleBookRemove} key={index} />
      ))}
    </div>
  )

  const error = {
    cta: 'Find my dream home',
    message:
      "You haven't booked any inspections yet. \n" +
      'To add an inspection to your cart, search our listings and click ‘Book my inspection’.',
    fullWidth: true
  }

  const result = {
    properties: cart.properties
  }

  const CartWithData = Data(PropertiesCards, { error, result })

  return (
    <div className="row">
      {/*Cart detail information*/}
      <div className={`col-12 col-lg-7 ${toggleInfo(isStripeFormVisible)}`}>
        <div className="mb-3">
          {getVerificationComponent()}

          <div className="mb-3">{<CartWithData />}</div>
        </div>
      </div>
      {/*Card detail information*/}
      <div className="col-12 col-lg-5">
        <div className={`${styles.card_container}`}>
          <h2 className="text-white h5 d-none d-lg-block">Card Details</h2>
          <div className={toggleCard(isStripeFormVisible)}>
            <div className="mb-3">{!hasCards && <NoPayment handleStripeForm={handleStripeForm} />}</div>
            <div className="mb-3">
              {hasCards && (
                <div>
                  <List
                    cards={cards}
                    handleStripeForm={handleStripeForm}
                    index={cardIndex}
                    updateIndex={setCardIndex}
                    updateCards={setCards}
                  />
                  <div className={`${styles.divider_gray} d-none d-lg-block`} />
                </div>
              )}
            </div>
            {errorMsg && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMsg}
              </div>
            )}
            <div className="mb-3">
              {!profile.digitalId && hasCards && (
                <div className={`${styles.verify} text-center`}>
                  <DigitalIdButton handleVerification={handleVerification} />
                </div>
              )}
            </div>
            <div className="pb-5">
              {showCheckoutButton ? <Action amount={amount} disabled={disabled} handleAction={handleCheckout} /> : null}
            </div>
          </div>
          {isStripeFormVisible && (
            <div>
              <SplitForm handleCard={handleAddCard} hideStripeForm={handleStripeForm} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StripeWrapper(Checkout)
