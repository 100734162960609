import styles from './inspection.module.scss'
import { CHECK, CLOCK, ELLIPSIS } from '../icons'
import React from 'react'

const Icon = ({ status }) => {
  const STATUS = {
    viewed: {
      className: 'inspection_viewed',
      icon: <CHECK />
    },
    progress: {
      className: 'inspection_progress',
      icon: <CLOCK />
    },
    pending: {
      className: 'inspection_pending',
      icon: <ELLIPSIS />
    }
  }
  return (
    <div className={styles.inspection_icon}>
      <span className={styles[STATUS[status].className]}>{STATUS[status].icon}</span>
    </div>
  )
}

export default Icon
