import React, { useState } from 'react'
import useFirebase from '../../hooks/useFirebase'
import $ from 'jquery'
import { emailCredential } from '../../services/firebaseAuth'

const ReAuthenticate = ({ setIsReAuth, setIsMFA, setResolver }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const app = useFirebase()
  const user = app.auth().currentUser

  const reAuthenticate = async e => {
    e.preventDefault()
    try {
      const credential = emailCredential(user.email, password)
      await user.reauthenticateWithCredential(credential)
      setIsReAuth(true)
      setPassword('')
      setError('')
      $('#reAuthenticateModal').modal('hide')
    } catch (e) {
      if (e.code === 'auth/multi-factor-auth-required') {
        setResolver(e.resolver)
        setIsMFA(true)
        setPassword('')
        $('#reAuthenticateModal').modal('hide')
      } else {
        setError(e.message)
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="reAuthenticateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="reAuthenticateModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Confirm your identity
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={reAuthenticate} id="re-auth-form">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  required
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>
              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  {error}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="submit" className="btn btn-primary" form="re-auth-form">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReAuthenticate
