import React from 'react'
import styles from './checkout.module.scss'
import { PLUS } from '../icons'
const NoPayment = ({ handleStripeForm }) => {
  return (
    <div className={`${styles.noPayment} text-center py-4`}>
      <h5 className={styles.noPayment_title}>No Credit Card added yet</h5>
      <p className={styles.noPayment_message}>You can add and edit payments during checkout</p>
      <div className="card shadow">
        <div className={`${styles.noPayment_container} card-body`}>
          <div className={styles.noPayment_elements}>
            <button className="btn" onClick={() => handleStripeForm()} type="button">
              <span className="d-block mb-3">
                <PLUS />
              </span>
              <span className="text-primary h5 d-block">Add Card</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NoPayment
