import { getName, handleSignIn, isBrowser } from '../../services/auth'
import { createUser } from '../../requests/buyerHttp'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import userContext from '../../context/userContext'
import useFirebase from '../../hooks/useFirebase'
import PhoneInput from 'react-phone-number-input/input'
import { captchaVerifier, sendVerificationCode, verifyCode } from '../../services/firebaseAuth'
import $ from 'jquery'
import MFAVerificationCode from './mfaVerificationModal'
import { navigate } from 'gatsby'
import { DASHBOARD } from '../../routes'

const MfaSetup = () => {
  const { setProfile } = useContext(userContext)
  const app = useFirebase()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [currentUser, setCurrentUser] = useState(app.auth().currentUser)
  const fullName = currentUser?.displayName || getName()

  const handleMFAEnroll = async e => {
    try {
      e.preventDefault()
      setError('')
      const multiFactorSession = await currentUser.multiFactor.getSession()
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession
      }
      await handleMFAAuth(phoneInfoOptions)
    } catch (e) {
      setError(e.message)
    }
  }

  const handleMFAAuth = useCallback(
    async phoneInfoOptions => {
      const verificationId = await sendVerificationCode(phoneInfoOptions, captcha)
      setVerificationId(verificationId)
      $('#mfaVerificationCode').modal('show')
    },
    [setVerificationId, captcha]
  )

  const handleVerificationCode = async code => {
    try {
      const multiFactorAssertion = await verifyCode(verificationId, code, captcha)
      await currentUser.multiFactor.enroll(multiFactorAssertion, 'My personal phone number')
      setSuccess('Your phone has been enrolled with your account')
      setVerificationId('')
      $('#mfaVerificationCode').modal('hide')
      await handleAccountCreation()
    } catch (e) {
      setError(e.message)
      const widgetId = await captcha.render()
      window.grecaptcha.reset(widgetId)
    }
  }

  const sendVerificationEmail = async () => {
    try {
      await currentUser.sendEmailVerification()
      setSuccess('Verification email sent')
    } catch (e) {
      setError(e.message)
    }
  }

  const handleAccountCreation = async () => {
    let result = await createUser({
      fullName,
      email: currentUser.email,
      firebaseUid: currentUser.uid,
      phoneNumber
    })
    result = { ...result, shortlist: result.shortlist.map(item => item.id) }
    result.multiFactor = currentUser.multiFactor
    setProfile(result)
    handleSignIn(result)
    navigate(DASHBOARD)
  }

  useEffect(() => {
    if (isBrowser() && app && !captcha) {
      setCurrentUser(app.auth().currentUser)
      setCaptcha(captchaVerifier('recaptcha-container'))
    }
  }, [app, captcha])

  return (
    <div>
      <div className="row mb-5">
        <div className="col-12 col-lg-6 mx-auto">
          <div className="text-center">
            <h1 className="my-5">
              <strong>Hi {fullName}!</strong>
            </h1>
            <p>To complete your registration please add your phone number for MFA enrol</p>
          </div>

          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success mt-3" role="alert">
              {success}
            </div>
          )}
          <form className="mb-lg-5" onSubmit={handleMFAEnroll}>
            <div className="form-group">
              <label htmlFor="phoneNumber" className="font-weight-bold">
                Mobile number
              </label>
              <PhoneInput
                placeholder="+61 412 345 678"
                className="form-control shadow-input"
                name="phoneNumber"
                id="phoneNumber"
                defaultCountry="AU"
                international
                withCountryCallingCode
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block btn-lg mb-5">
                Enrol phone number
              </button>
            </div>
            {!currentUser?.emailVerified && (
              <div className="form-group">
                <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={sendVerificationEmail}>
                  Verify Email
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <MFAVerificationCode handleVerificationCode={handleVerificationCode} error={error} />
      <div id="recaptcha-container"></div>
    </div>
  )
}
export default MfaSetup
