import styles from './thanks.module.scss'
import { CHECK } from '../icons'
import React from 'react'
import { MY_INSPECTIONS } from '../../routes'
import { Link } from 'gatsby'

const Card = ({ code, card }) => {
  const message = code
    ? 'All booked! Find your unique code under My Inspections page.'
    : `Woo hoo, you’re through! 
As a verified human you can now book any property you like`
  return (
    <div className="card shadow my-5 px-lg-5 pb-3">
      <div className={styles.thanks_icon}>
        <span className={styles.thanks_check}>
          <CHECK />
        </span>
      </div>
      <div className="card-body mt-5  text-center ">
        <div className="px-lg-5">
          {card && (
            <h1 className="h4 text-secondary  mb-3">Payment with your card ended in {card} has been successful</h1>
          )}
          <p className="h3 text-primary mb-3 font-weight-bold">{message}</p>
          {code && (
            <div className="px-lg-5 mx-lg-5">
              <p className="text-secondary font-weight-light">
                We’ve also sent the booking code to your email so you can have it on-hand for your inspection
              </p>
              <Link to={MY_INSPECTIONS} className="btn btn-primary btn-lg">
                See My Inspections
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
