import React from 'react'
import Card from './card'
import { Redirect, useLocation } from '@reach/router'
import { DASHBOARD } from '../../routes'

const Thanks = () => {
  const location = useLocation()
  const state = location.state ? location.state : {}
  const { fromCheckout = false, card } = state

  if (!fromCheckout) return <Redirect to={DASHBOARD} noThrow />
  else {
    return (
      <div className="row">
        <div className="col col-lg-9 mx-auto">
          <div className="mb-5 mx-auto">
            <Card code={fromCheckout} card={card} />
          </div>
        </div>
      </div>
    )
  }
}

export default Thanks
