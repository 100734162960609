import styles from './property.module.scss'
import React from 'react'
import { SUITCASE } from '../icons'

const Agent = ({ agent }) => (
  <div className="mb-3">
    <h3 className={styles.detail_subtitle}>
      <SUITCASE /> Agent Information
    </h3>
    <div>
      <span className="small">
        <span className="font-weight-bold">Name</span> {agent.name}
      </span>
    </div>
    <div>
      <span className="small">
        <span className="font-weight-bold">Agency</span> {agent.agency}
      </span>
    </div>
    <div>
      <span className="small">
        <span className="font-weight-bold">E-Mail</span> <a href={`mailto:${agent.email}`}>{agent.email}</a>
      </span>
    </div>
    <div>
      <span className="small">
        <span className="font-weight-bold">Phone Number</span>{' '}
        <a href={`tel:${agent.phoneNumber}`}>{agent.phoneNumber}</a>
      </span>
    </div>
  </div>
)

export default Agent
