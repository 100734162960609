import Inspections from '../components/inspection/index'
import Checkout from '../components/checkout/checkout'
import Verification from '../components/verify/verification'
import Shortlist from '../components/property/shortlist'
import PrivateLink from '../components/common/privateLink'
import Thanks from '../components/thanks/thanks'
import Results from '../components/property/results'
import * as Routes from '../routes'
import React from 'react'
import { Router } from '@reach/router'
import Account from '../components/account'
import Login from '../components/account/login'
import SignIn from '../components/account/signin'
import LayoutRenter from '../components/layout/layoutRenter'
import Property from '../components/property'
import ForgotPassword from '../components/account/forgotPassword'
import MfaSetup from '../components/account/mfa-setup'

const App = () => {
  return (
    <LayoutRenter>
      <Router>
        <PrivateLink component={Inspections} path={Routes.MY_INSPECTIONS} />
        <PrivateLink component={Checkout} path={Routes.CHECKOUT} />
        <PrivateLink component={Verification} path={Routes.VERIFICATION} />
        <PrivateLink component={Shortlist} path={Routes.SHORTLIST} />
        <PrivateLink component={Thanks} path={Routes.THANKS} />
        <PrivateLink component={Account} path={Routes.ACCOUNT} />
        <Results path={Routes.DASHBOARD} />
        <Login path={Routes.LOGIN} />
        <SignIn path={Routes.SIGNIN} />
        <ForgotPassword path={Routes.FORGOT_PASSWORD} />
        <Property path={`${Routes.PROPERTY}:slug`} />
        <MfaSetup path={Routes.MFA_SETUP} />
      </Router>
    </LayoutRenter>
  )
}
export default App
