import React, { useContext } from 'react'
import Verify from './verify'
import { DASHBOARD } from '../../routes'
import { Redirect } from '@reach/router'
import userContext from '../../context/userContext'

const Verification = () => {
  const { profile } = useContext(userContext)
  const isVerified = profile.verified

  if (isVerified) return <Redirect to={DASHBOARD} noThrow />
  else {
    return (
      <div>
        <Verify />
      </div>
    )
  }
}
export default Verification
