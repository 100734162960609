import { SHARE } from '../icons'
import React, { useEffect } from 'react'
import { isBrowser } from '../../services/auth'
import $ from 'jquery'

const shareTxt = 'Copy to clipboard'
const Share = ({ url }) => {
  const copyToClipboard = () => {
    if (isBrowser()) {
      const target = $('#btn-share')
      navigator.clipboard.writeText(url).then(() => {
        target.tooltip('dispose').tooltip({ title: 'Copied to Clipboard!' }).tooltip('show')
        setTimeout(() => {
          target.tooltip('dispose').tooltip({ title: shareTxt }).tooltip('hide')
        }, 800)
      })
    }
  }
  useEffect(() => {
    $(function () {
      $('#btn-share').tooltip({ title: shareTxt })
    })
  })
  return (
    <div>
      <button
        className="btn p-0"
        onClick={copyToClipboard}
        data-toggle="tooltip"
        data-placement="auto"
        type="button"
        id="btn-share"
      >
        <SHARE /> <span className="sr-only">share</span>
      </button>
    </div>
  )
}

export default Share
