import React from 'react'
import styles from './verify.module.scss'
import { Link } from 'gatsby'
import * as Routes from '../../routes'

const Card = ({ goToCheckout, verificationCost, chargeVerification }) => {
  const verificationMessage = chargeVerification
    ? `Once you’ve added your preferred payment method, we’ll automatically deduct the fee for your Digital iD
              verification.`
    : goToCheckout
    ? `To check you’re a legit human, we need to verify your identity.`
    : `To check you’re a legit human, we need to verify your identity and record your credit card info (don’t worry you won’t be charged!)`
  return (
    <div className={`${styles.verify_card} card mb-3`}>
      <div className="card-header">
        <div className="row">
          <div className="col-12 text-left">
            <span className="text-primary h5 font-weight-semi">Verify Yourself</span>
          </div>
        </div>
      </div>
      <div className="card-body text-left">
        <div className="row">
          <div className={`${goToCheckout ? 'col-lg-9' : 'col-lg-10'} col-8`}>
            <p className="small">{verificationMessage}</p>
          </div>
          <div className={`${goToCheckout ? 'col-lg-3 text-md-left' : 'col-lg-2'} col-4 text-right`}>
            <span className={styles.verify_price}>{chargeVerification ? `$ ${verificationCost}` : `FREE`}</span>
            {goToCheckout && (
              <Link
                className="btn btn-outline-primary float-right mt-3 mt-md-0"
                to={Routes.CHECKOUT}
                state={{ fromVerificationPage: true }}
              >
                VERIFY
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
