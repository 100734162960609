import Feature from './feature'
import React from 'react'

const upperToSpaceLower = match => ' ' + match.toLowerCase()
const convertFeature = feature => feature.replace(/[A-Z]/gm, upperToSpaceLower)

const FeatureSection = ({ ecoFeatures, healingCooling, indoorFeature, outdoorFeatures }) => {
  return (
    <div className="d-flex flex-wrap">
      {Object.keys(ecoFeatures)
        .filter(item => ecoFeatures[item] === true)
        .map((key, index) => {
          return <Feature key={index}>{convertFeature(key)}</Feature>
        })}
      {Object.keys(healingCooling)
        .filter(item => healingCooling[item] === true)
        .map((key, index) => {
          return <Feature key={index}>{convertFeature(key)}</Feature>
        })}
      {Object.keys(indoorFeature)
        .filter(item => indoorFeature[item] === true)
        .map((key, index) => {
          return <Feature key={index}>{convertFeature(key)}</Feature>
        })}
      {Object.keys(outdoorFeatures)
        .filter(item => outdoorFeatures[item] === true)
        .map((key, index) => {
          return <Feature key={index}>{convertFeature(key)}</Feature>
        })}
    </div>
  )
}

export default FeatureSection
