import React, { useContext } from 'react'
import { isBrowser } from '../../services/auth'
import { navigate } from 'gatsby'
import * as Routes from '../../routes'
import userContext from '../../context/userContext'

/**
 * Returns the passed component if the user is logged in otherwise redirects the user to the Login page
 * @param {Object} props - Component's props
 * @param {function} props.component - Component to be returned
 * @param {Object} [props.location] - Browser location inherit from parent component
 * @param {Object} [props.rest] - Props to set to the component returned
 * @return {function(): JSX.Element|null}
 * @constructor
 */
const PrivateLink = ({ component: Component, location, ...rest }) => {
  const { profile } = useContext(userContext)
  if (!profile && location.pathname !== `/app/login`) {
    if (isBrowser()) {
      navigate(Routes.LOGIN)
    }
    return null
  }
  return <Component {...rest} />
}
export default PrivateLink
