const { request } = require('./index')
/**
 * Gets the setting configuration model from DatoCMS
 * @return {{settingCollection: Object}}
 */
module.exports.getSettings = () => {
  const query = `
    query SettingsQuery {
      settingCollection {
        chargeBooking
        chargeVerification
        verificationCost
        currency
        bookingCost
      }
    }
  `
  return request({ query, variables: {} })
}
