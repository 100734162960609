import { useEffect, useState } from 'react'
import { getSettings } from '../requests/settingsHTTP'

export default function useSettings() {
  const [settings, setSettings] = useState({})

  useEffect(() => {
    const fetchSettings = async () => {
      const { settingCollection } = await getSettings()
      setSettings(settingCollection)
    }
    fetchSettings()
  }, [])

  return settings
}
