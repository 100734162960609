import React, { useEffect, useRef } from 'react'
import { isBrowser } from '../../services/auth'
import { loadMap } from '../../services/map'

let map
const initMap = (mapRef, lat, lng) => {
  if (window.google) {
    map = new google.maps.Map(mapRef.current, {
      center: { lat, lng },
      zoom: 15
    })

    new google.maps.Marker({
      position: { lat, lng },
      map
    })
  }
}
const Map = ({ lat, lng }) => {
  const mapRef = useRef('')

  useEffect(() => {
    if (isBrowser()) {
      if (window.initMap === undefined) {
        loadMap(() => initMap(mapRef, lat, lng))
      } else {
        initMap(mapRef, lat, lng)
      }
    }
  })

  return <div id="map" ref={mapRef} />
}

export default Map
