import React, { useCallback, useEffect, useState } from 'react'
import { isBrowser, isLoggedIn } from '../../services/auth'
import { Redirect } from '@reach/router'
import { DASHBOARD, FORGOT_PASSWORD, SIGNIN } from '../../routes'
import { Link, navigate } from 'gatsby'
import Google from './google'
import useFirebase from '../../hooks/useFirebase'
import { captchaVerifier, sendVerificationCode, verifyCode } from '../../services/firebaseAuth'
import $ from 'jquery'
import MFAVerificationCode from './mfaVerificationModal'

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })

  const [error, setError] = useState('')
  const [captcha, setCaptcha] = useState(null)
  const [verificationId, setVerificationId] = useState('')
  const [mfaError, setMFAError] = useState('')
  const [resolver, setResolver] = useState(null)
  const [isMFA, setMFA] = useState(false)
  const app = useFirebase()

  const handleCredential = e => {
    const value = e.target.value
    const name = e.target.name
    setCredentials({ ...credentials, [name]: value })
  }

  const handleLogin = async e => {
    e.preventDefault()
    setError('')
    try {
      if (isBrowser() && app) {
        const { email, password } = credentials
        await app.auth().signInWithEmailAndPassword(email, password)
      }
    } catch (e) {
      if (e.code === 'auth/multi-factor-auth-required') {
        setResolver(e.resolver)
        setMFA(true)
      } else {
        setError(e.message)
      }
    }
  }

  const handleVerificationCode = async code => {
    try {
      const multiFactorAssertion = await verifyCode(verificationId, code, captcha)
      await resolver.resolveSignIn(multiFactorAssertion)
      $('#mfaVerificationCode').modal('hide')
      navigate(DASHBOARD)
    } catch (e) {
      setError(e.message)
      setMFAError(e.message)
      const widgetId = await captcha.render()
      window.grecaptcha.reset(widgetId)
    }
  }

  const handleMFA = useCallback(async () => {
    if (isBrowser()) {
      try {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        }
        const verificationId = await sendVerificationCode(phoneInfoOptions, captcha)
        setVerificationId(verificationId)
        $('#mfaVerificationCode').modal('show')
      } catch (e) {
        setError(e.message)
        const widgetId = await captcha.render()
        window.grecaptcha.reset(widgetId)
      }
    }
  }, [resolver, captcha, setVerificationId])

  useEffect(() => {
    if (isBrowser() && app && !captcha) {
      setCaptcha(captchaVerifier('recaptcha-container'))
    }
  }, [app, captcha])

  useEffect(() => {
    const requestMFA = async () => {
      if (resolver && isMFA) await handleMFA()
    }
    requestMFA()
  }, [resolver, isMFA, handleMFA])

  if (isLoggedIn()) return <Redirect to={DASHBOARD} noThrow />
  else
    return (
      <div className="row">
        <div className="col-12 col-lg-6 mx-auto">
          <div>
            <h1 className="font-weight-bold mb-5">Nice to see you again! Log in to your account</h1>
            <div className="my-5">
              <form onSubmit={e => handleLogin(e)}>
                <div className="form-group">
                  <label htmlFor="email" className="font-weight-bold">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control shadow-input"
                    name="email"
                    id="email"
                    required
                    value={credentials.email}
                    onChange={e => handleCredential(e)}
                    placeholder="wayne@gmail.com"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="font-weight-bold">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control shadow-input"
                    name="password"
                    id="password"
                    required
                    value={credentials.password}
                    onChange={e => handleCredential(e)}
                    placeholder="MySuperSecretPassword"
                  />
                </div>
                {error && (
                  <div className="alert-danger alert" role="alert">
                    {error}
                  </div>
                )}
                <div>
                  <p>
                    <Link to={FORGOT_PASSWORD}>Forgot your password?</Link>
                  </p>
                </div>
                <div className="row align-items-center my-4">
                  <div className="col-lg-4 col-12 text-lg-right order-lg-1 mb-4">
                    <button className="btn btn-primary btn-block" type="submit" id="log-in">
                      Log in
                    </button>
                  </div>
                  <div className="col-lg-8 col-12 order-lg-0 text-center text-lg-left">
                    <p>
                      Don’t have an account yet? <Link to={SIGNIN}>Create one</Link>
                    </p>
                  </div>
                </div>
              </form>
              <Google setError={setError} setResolver={setResolver} setMFA={setMFA} />
              <MFAVerificationCode handleVerificationCode={handleVerificationCode} error={mfaError} />
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default Login
