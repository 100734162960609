import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { InspectionDate, InspectionTime } from '../../constants'
import { getTimeAvailable } from './util'
import $ from 'jquery'
import { isBrowser } from '../../services/auth'

const AvailableSlot = (startTime, endTime, events) => {
  let currentTime = moment(startTime)
  let slots = []
  const dates = events.map(item => item.date)
  if (startTime && endTime)
    while (currentTime.isBefore(endTime)) {
      const value = currentTime.toISOString()
      slots.push(
        <option value={value} key={value} disabled={dates.includes(value)}>
          {currentTime.format(InspectionTime)}
        </option>
      )
      currentTime.add(1, 'h')
    }
  return slots
}
const BookingModal = ({ bookingDate, events, times, addEvent, setHasBooking, propertyId }) => {
  const [selectedHour, setSelectedHour] = useState('')
  const [from, setFrom] = useState('')
  const [until, setUntil] = useState('')
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)

  useEffect(() => {
    if (bookingDate) {
      setSelectedHour(bookingDate)
      const { daySelectedOpens, daySelectedCloses } = getTimeAvailable(times, bookingDate)
      setFrom(moment(selectedHour).toISOString())
      setUntil(moment(selectedHour).add(1, 'h').format(InspectionTime))
      setStartTime(daySelectedOpens)
      setEndTime(daySelectedCloses)
    }
  }, [bookingDate, selectedHour, times])

  const handleDateChange = e => {
    const value = e.target.value
    setFrom(moment(value).toISOString())
    setUntil(moment(value).add(1, 'h').format(InspectionTime))
  }

  const handleAddEvent = () => {
    const savedEvents = events.filter(item => item.id !== propertyId)
    addEvent([
      ...savedEvents,
      {
        date: from,
        id: propertyId
      }
    ])
    setHasBooking(true)
    $('#bookingModal').modal('hide')
    if (isBrowser()) {
      setTimeout(() => {
        $('html, body').animate(
          {
            scrollTop: $('#btnAddBooking').offset().top
          },
          300
        )
      }, 300)
    }
  }

  return (
    <div
      className="modal fade "
      id="bookingModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {moment(bookingDate).format(InspectionDate)}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="from" className="font-weight-bold">
                    From
                  </label>
                  {from && (
                    <select
                      name="from"
                      id="from"
                      value={from}
                      onBlur={handleDateChange}
                      onChange={handleDateChange}
                      className="form-control"
                    >
                      {AvailableSlot(startTime, endTime, events).map(item => item)}
                    </select>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="until" className="font-weight-bold">
                    Until
                  </label>
                  <input
                    type="text"
                    disabled
                    readOnly
                    defaultValue={until}
                    id="until"
                    name="until"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary btn-block btn-lg"
              data-dismiss="modal"
              onClick={handleAddEvent}
            >
              This time works for me
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingModal
