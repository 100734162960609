import React from 'react'
import { isBrowser } from '../../services/auth'
import useFirebase from '../../hooks/useFirebase'
import { googleAuthProvider } from '../../services/firebaseAuth'

const Google = ({ setError, setResolver, setMFA }) => {
  const app = useFirebase()
  const googleProvider = googleAuthProvider()
  const logWithGoogle = async () => {
    try {
      if (isBrowser() && app) {
        await app.auth().signInWithPopup(googleProvider)
      }
    } catch (e) {
      if (e.code === 'auth/multi-factor-auth-required') {
        setResolver(e.resolver)
        setMFA(true)
      } else {
        await app.auth().signOut()
        setError(e.message)
      }
    }
  }
  return (
    <div>
      <div className="my-3 or-divider">
        <span className="bg-white px-2">OR</span>
      </div>
      <div className="text-center my-5">
        <button type="button" className="btn btn-google" onClick={logWithGoogle}>
          Sign in with Google
        </button>
      </div>
    </div>
  )
}

export default Google
