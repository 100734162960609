import React, { useState } from 'react'

const MFAVerificationCode = ({ error, handleVerificationCode }) => {
  const [code, setCode] = useState('')

  const submitMFACode = e => {
    e.preventDefault()
    handleVerificationCode(code)
    setCode('')
  }
  return (
    <div
      className="modal fade"
      id="mfaVerificationCode"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="reAuthenticateModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Enter your verification code
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={submitMFACode} id="mfa-form">
              <div className="form-group">
                <label htmlFor="code">Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code"
                  required
                  value={code}
                  onChange={event => setCode(event.target.value)}
                />
              </div>
              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  {error}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="submit" className="btn btn-primary" form="mfa-form">
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MFAVerificationCode
