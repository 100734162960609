import React, { useEffect, useState } from 'react'
import { getPropertyBySlug } from '../../requests/propertyHTTP'
import Details from './details'
import Empty from '../common/empty'
import Loader from '../common/loader'

let propertyDetails = {},
  album = []
const Index = ({ slug }) => {
  const [property, setProperty] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const { propertyCollection: result } = await getPropertyBySlug(slug)
      if (result) {
        album = [...result.gallerySection[0].album, result.gallerySection[0].portraitPhoto]
        propertyDetails = {
          bedrooms: result.bedrooms,
          bathrooms: result.bathrooms,
          ensuites: result.ensuites,
          toilets: result.toilets,
          livingAreas: result.livingAreas,
          outdoorArea: result.outdoorArea,
          houseSize: result.houseSize,
          landSize: result.landSize
        }
        setProperty(result)
      }
      setLoading(false)
    }
    fetchData()
  }, [slug])

  return loading ? (
    <Loader />
  ) : property ? (
    <Details
      id={property.id}
      title={property.title}
      propertyType={property.propertyType}
      dateAvailable={property.dateAvailable}
      hidePrice={property.hidePrice}
      listingType={property.listingType}
      listingPrice={property.listingPrice}
      monthlyPrice={property.monthlyPrice}
      securityBond={property.securityBond}
      description={property.listingDescription}
      agent={property.agent}
      propertyDetails={propertyDetails}
      ecoFeatures={property.ecoFriendlyFeatures[0]}
      healingCooling={property.healingCooling[0]}
      indoorFeature={property.indoorFeatures[0]}
      outdoorFeatures={property.outdoorFeatures[0]}
      statementInformation={property.statementInformation}
      floorPlan={property.gallerySection[0].floorPlan}
      video={property.gallerySection[0].videoUrl}
      location={property.location}
      album={album}
      locationAddress={property.locationAddress}
      inspectionsTime={property.inspection}
    />
  ) : (
    <Empty message={'Sorry we were unable to found the property you are looking'} cta={'Go back to Dashboard'} />
  )
}
export default Index
