import React from 'react'
import styles from './checkout.module.scss'
import { PLUS, TRASH } from '../icons'
import $ from 'jquery'
import amex from '../../assets/amex.png'
import card from '../../assets/card.png'
import diners from '../../assets/diners.png'
import mastercard from '../../assets/mastercard.png'
import visa from '../../assets/visa.png'
import { removeCardById } from '../../requests/paymentHttp'

const Preview = ({ name, number, card }) => (
  <span className={`${styles.cards_item} small`}>
    <img src={getCardLogo(card)} alt={card} width={24} /> {name} <span className="align-middle">****</span> {number}
  </span>
)

const NewCard = ({ action }) => (
  <button type="button" className="btn btn-primary btn-block text-right" onClick={() => action()}>
    <span className={styles.action_amount}>Add card</span>
    <span className={styles.action_text}>
      <PLUS />
    </span>
  </button>
)

const getCardLogo = cardName => {
  let image
  switch (cardName) {
    case 'amex':
      image = amex
      break
    case 'diners':
      image = diners
      break
    case 'mastercard':
      image = mastercard
      break
    case 'visa':
      image = visa
      break
    default:
      image = card
      break
  }
  return image
}
const List = ({ cards, handleStripeForm, index, updateIndex, updateCards }) => {
  const closeCards = () => {
    $('#cardsCollapse').collapse('toggle')
  }
  const handleCards = index => {
    updateIndex(index)
    closeCards()
  }
  const addCard = () => {
    closeCards()
    handleStripeForm()
  }
  const deleteCard = async index => {
    try {
      const { id } = cards[index]
      await removeCardById(id)
      updateCards(cards => cards.filter(item => item.id !== id))
    } catch (e) {
      console.error(e.message)
    }
  }
  const parsedCard = cards.map(item => {
    const {
      billing_details: { name },
      card: { brand: card, last4: number },
      id
    } = item
    return { name, card, number, id }
  })
  return (
    <div>
      <p className="d-lg-none">
        <strong>Cards</strong>
      </p>
      <button
        className={`btn ${styles.cards_dropdown}`}
        type="button"
        data-toggle="collapse"
        data-target="#cardsCollapse"
        aria-expanded="false"
        aria-controls="cardsCollapse"
      >
        <Preview {...parsedCard[index]} />
      </button>
      <div className={`collapse ${styles.cards_menu}`} id="cardsCollapse">
        <div className="p-3 ">
          {parsedCard.map((card, index) => (
            <div key={index}>
              <button
                type="button"
                name="select-card"
                className="btn btn-link px-0 text-left"
                onClick={() => handleCards(index)}
              >
                <Preview {...card} />
              </button>
              <button
                type="button"
                name="delete-card"
                className={`btn p-0 float-right ${styles.cards_trash}`}
                onClick={() => deleteCard(index)}
              >
                <span className="sr-only">delete card</span>
                <TRASH />
              </button>
            </div>
          ))}
          <div className={styles.divider_white} />
          <NewCard action={addCard} />
        </div>
      </div>
    </div>
  )
}

export default List
