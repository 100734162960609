import React from 'react'
import { CHECKOUT } from '../../routes'
import $ from 'jquery'
import { navigate } from '@reach/router'

const NotificationModal = () => {
  const goToCheckout = () => {
    $('#bookingNotificationModal').modal('hide')
    navigate(CHECKOUT)
  }
  return (
    <div
      className="modal fade"
      id="bookingNotificationModal"
      role="dialog"
      aria-labelledby="bookingNotificationTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="bookingNotificationTitle">
              Booking Notification
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Inspection time was successfully added to your cart. What would you like to do next?</p>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Keep searching
            </button>
            <button className="btn btn-primary" onClick={goToCheckout}>
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal
