import React from 'react'
import { BATH, BED, CALENDAR, CLOCK, EMAIL, LOCATION, PHONE, TOILET, USER } from '../icons'
import styles from './inspection.module.scss'
import { Link } from 'gatsby'
import * as Routes from '../../routes'
import Icon from './icon'
import { formatAddress } from '../property/util'

const Item = ({ children }) => <div className="mb-lg-4 mb-0">{children}</div>

const Card = ({ inspection, day, lockboxCode, status }) => {
  const { bathrooms: baths, bedrooms: beds, toilets } = inspection
  const street = formatAddress(inspection.locationAddress[0])
  return (
    <div className={`${styles.inspection} card mb-3 mb-lg-5`}>
      <Link to={`${Routes.PROPERTY}${inspection.slug}`}>
        <div className="row">
          <div className="col-lg-4 col-lg">
            <img
              src={inspection.gallerySection[0].portraitPhoto.responsiveImage.src}
              alt={inspection.gallerySection[0].portraitPhoto.alt}
              className="img-fluid d-none d-lg-block"
            />
          </div>
          <div className="col col-lg-3">
            <div className={`${styles.inspection_items} d-flex  flex-row flex-lg-column align-items-center`}>
              <Item>
                <span className="ellipsis">
                  <span className="d-none d-lg-inline">
                    <LOCATION />
                  </span>
                  {street}
                </span>
              </Item>

              <Item>
                <div className="d-flex align-items-center">
                  <div>
                    <CALENDAR />
                  </div>
                  <div className="px-2 px-lg-0">
                    <span className={styles.inspection_date}> {day.date}</span>
                  </div>
                </div>
              </Item>
              <div className="w-25">
                <Item>
                  <CLOCK />
                  <span className={styles.inspection_date}>{day.time}</span>
                </Item>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className={`d-none d-lg-block text-center ${styles.border}`}>
              <Item>
                <BED />x{beds}
              </Item>
              <Item>
                <BATH />x{baths}
              </Item>
              <Item>
                <TOILET />x{toilets}
              </Item>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="d-none d-lg-block">
              <Item>
                <USER />
                {inspection.agent.name}
              </Item>
              <Item>
                <PHONE />
                {inspection.agent.phoneNumber}
              </Item>
              <Item>
                <EMAIL />
                {inspection.agent.email}
              </Item>
            </div>
          </div>
        </div>
        <div className="bg-secondary rounded-lg text-white py-2 px-3 mb-lg-3 mt-lg-0 mb-2 mt-3">
          <span>Unique Code</span>
          <span className="float-right">{lockboxCode}</span>
        </div>
      </Link>
      <Icon status={status} />
    </div>
  )
}

export default Card
