import React from 'react'
import { TRASH } from '../icons'
import styles from './checkout.module.scss'
import { DateTimeFormat } from '../../constants'
import moment from 'moment'
import { Link } from '@reach/router'
import * as Routes from '../../routes'

const Card = ({ address, propertyId, slug, handleRemove, inspectionPrice, date, taken }) => {
  return (
    <div className={styles.property}>
      <div className={`${taken ? styles.property_taken : ''} card mb-3 shadow`}>
        <div className="card-header">
          <div className="row">
            <div className="col-10 col-lg-11 pr-0">
              <Link to={`${Routes.PROPERTY}${slug}`}>
                <span className="h5 text-primary d-block font-weight-semi">{address}</span>
              </Link>
            </div>
            <div className="col-2 col-lg-1 text-right">
              <button
                className={`${styles.trash} btn p-0`}
                onClick={() => {
                  handleRemove(propertyId)
                }}
              >
                <TRASH />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="mb-3 d-flex justify-content-between">
            <span>Inspection date:</span>
            <span className={styles.property_code}>{moment(date).format(DateTimeFormat)}</span>
          </div>
          {taken ? (
            <div className="text-center">
              <p>
                Someone beat you to it —this time slot has already been booked. Check for other slots that suit you.
              </p>
              <p>
                <Link className="btn btn-outline-primary" to={`${Routes.PROPERTY}${slug}`}>
                  Reschedule
                </Link>
              </p>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <span>Inspection Payment:</span>
              <span className={styles.property_price}>{inspectionPrice > 0 ? `$ ${inspectionPrice}` : 'FREE'} </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
