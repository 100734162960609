import React, { useCallback, useEffect, useState } from 'react'
import { isBrowser, isLoggedIn, setName } from '../../services/auth'
import { Redirect } from '@reach/router'
import { DASHBOARD, LOGIN } from '../../routes'
import { Link, navigate } from 'gatsby'
import Google from './google'
import useFirebase from '../../hooks/useFirebase'
import { captchaVerifier, sendVerificationCode, verifyCode } from '../../services/firebaseAuth'
import $ from 'jquery'
import MFAVerificationCode from './mfaVerificationModal'

const SignIn = () => {
  const [user, setUser] = useState({
    fullName: '',
    email: '',
    password: '',
    verify: ''
  })
  const [error, setError] = useState('')
  const app = useFirebase()
  const handleUser = e => {
    const value = e.target.value
    const name = e.target.name
    setUser({ ...user, [name]: value })
  }
  const [captcha, setCaptcha] = useState(null)
  const [verificationId, setVerificationId] = useState('')
  const [mfaError, setMFAError] = useState('')
  const [resolver, setResolver] = useState(null)
  const [isMFA, setMFA] = useState(false)

  const signInUser = async e => {
    e.preventDefault()
    if (user.verify === user.password) {
      try {
        if (isBrowser() && app) {
          const { fullName, email, password } = user
          setName(fullName)
          await app.auth().createUserWithEmailAndPassword(email, password)
          const currentUser = app.auth().currentUser
          await currentUser.sendEmailVerification()
        }
      } catch (e) {
        console.error(e)
        await app.auth().signOut()
        setError(e.message)
      }
    } else {
      setError(`Passwords don't match`)
    }
  }

  const handleVerificationCode = async code => {
    try {
      const multiFactorAssertion = await verifyCode(verificationId, code, captcha)
      await resolver.resolveSignIn(multiFactorAssertion)
      $('#mfaVerificationCode').modal('hide')
      navigate(DASHBOARD)
    } catch (e) {
      setError(e.message)
      setMFAError(e.message)
      const widgetId = await captcha.render()
      window.grecaptcha.reset(widgetId)
    }
  }

  const handleMFA = useCallback(async () => {
    if (isBrowser()) {
      try {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        }
        const verificationId = await sendVerificationCode(phoneInfoOptions, captcha)
        setVerificationId(verificationId)
        $('#mfaVerificationCode').modal('show')
      } catch (e) {
        setError(e.message)
        const widgetId = await captcha.render()
        window.grecaptcha.reset(widgetId)
      }
    }
  }, [resolver, captcha, setVerificationId])

  useEffect(() => {
    if (isBrowser() && app && !captcha) {
      setCaptcha(captchaVerifier('recaptcha-container'))
    }
  }, [app, captcha])

  useEffect(() => {
    const requestMFA = async () => {
      if (resolver && isMFA) await handleMFA()
    }
    requestMFA()
  }, [resolver, isMFA, handleMFA])

  if (isLoggedIn()) return <Redirect to={DASHBOARD} noThrow />
  else
    return (
      <div className="row">
        <div className="col-12 col-lg-6 mx-auto">
          <div>
            <h1 className="font-weight-bold mb-5">Create your account</h1>
            <div className="my-5">
              <form onSubmit={e => signInUser(e)}>
                <div className="form-group">
                  <label htmlFor="fullName" className="font-weight-bold">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-input"
                    name="fullName"
                    id="fullName"
                    required
                    value={user.fullName}
                    onChange={e => handleUser(e)}
                    placeholder="Wayne Rooney"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="font-weight-bold">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control shadow-input"
                    name="email"
                    id="email"
                    required
                    value={user.email}
                    onChange={e => handleUser(e)}
                    placeholder="wayne@gmail.com"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="font-weight-bold">
                    Password
                  </label>
                  <span className="small d-block">Choose a password that’s at least eight characters long</span>
                  <input
                    type="password"
                    className="form-control shadow-input"
                    name="password"
                    id="password"
                    required
                    value={user.password}
                    onChange={e => handleUser(e)}
                    placeholder="MySuperSecretPassword"
                    minLength={8}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="verify" className="font-weight-bold">
                    Verify Password
                  </label>
                  <span className="small d-block">Type your password again and we’ll make sure they match</span>
                  <input
                    type="password"
                    className="form-control shadow-input"
                    name="verify"
                    id="verify"
                    required
                    value={user.verify}
                    onChange={e => handleUser(e)}
                    placeholder="MySuperSecretPassword"
                    minLength={8}
                  />
                </div>
                {error && (
                  <div className="alert-danger alert" role="alert">
                    {error}
                  </div>
                )}
                <div className="row align-items-center my-4">
                  <div className="col-lg-4 col-12 text-lg-right order-lg-1 mb-4">
                    <button className="btn btn-primary btn-block" type="submit">
                      Join Uthru
                    </button>
                  </div>
                  <div className="col-12 order-lg-2 mb-5">
                    <p className="text-gray text-center text-lg-left">
                      By completing and submitting this form you agree to the <Link to="#">terms and conditions.</Link>
                    </p>
                  </div>
                  <div className="col-lg-8 col-12 order-lg-0 text-center text-lg-left">
                    <p>
                      Already have an account? <Link to={LOGIN}>Log in</Link>
                    </p>
                  </div>
                </div>
              </form>
              <Google setError={setError} setMFA={setMFA} setResolver={setResolver} />
              <MFAVerificationCode handleVerificationCode={handleVerificationCode} error={mfaError} />
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default SignIn
