const axios = require('axios')
let URL = 'http://localhost:8888/.netlify/functions'
if (typeof window !== 'undefined') {
  if (!window.location.hostname.includes('localhost')) {
    URL = `https://${window.location.hostname}/.netlify/functions`
  }
}
const currency = 'aud'
axios.defaults.baseURL = URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

/**
 * @typedef booking
 * @type {Object}
 * @property {string} propertyId - Property's id
 * @property {Date} date - Booking's date
 * @property {string} address - Property's address
 * @property {string} slug - Property's slug
 * @property {string} image - Property's portrait photo url
 */

/**
 * @deprecated No longer in usage **getCartPayment** will handle charges for verification
 * Gets the Stripe Payment intent associated to the Verification process
 * saves the information on the CMS
 * @param {Object} customerId - Object containing the Customer ID
 * @return {AxiosPromise<Object>} - Object containing the Stripe's Client Secret and customerId
 */
module.exports.getVerificationPayment = ({ customerId }) => {
  return axios({
    method: 'post',
    url: '/createVerificationIntent',
    data: {
      currency,
      customerId
    }
  })
}

/**
 *
 * Pull Digital ID information form the recent verify buyer and
 * saves the information on the CMS
 * @param {string} id - Buyer IO
 * @param {string} digitalIdCode - Digital ID response code after successful verification
 * @return {AxiosPromise<{digitalId: boolean, verified: boolean}>} - Object containing Buyer Digital ID and verified field values
 */
module.exports.verifyBuyer = (id, digitalIdCode) => {
  return axios.post('/verifyCustomer', {
    id,
    digitalIdCode
  })
}

/**
 * Gets the Stripe Payment intent associated to the checkout cart
 * @param {string} id - Buyer ID
 * @param cancelToken - Axios Cancel Token
 * @return {AxiosPromise<{id: string, clientSecret: string, amount: number}>} - Object containing the Stripe's Client Secret, payment intent id and amount
 */
module.exports.getCartPayment = (id, cancelToken) => {
  return axios.post('/createPaymentIntent', { id }, { cancelToken })
}

/**
 * Gets the credits cards registered by the Buyer
 * @param {string} customerId - Stripe's customer ID
 * @return {AxiosPromise<Stripe<PaymentMethodData>>} - Object containing Stripe payment methods
 */
module.exports.getCards = customerId => {
  return axios.post('/getPaymentMethod', { customerId })
}

/**
 * Removes a credit card from Stripe
 * @param {string} id - Payment Method ID
 * @return {AxiosPromise<Stripe<PaymentMethodData>>} - Object containing Stripe payment method deleted
 */
module.exports.removeCardById = id => {
  return axios.post('/deletePaymentMethod', { id })
}

/**
 * Gets the Stripe Setup intent associated to the user wallet
 * @param {string} id - Payment Method ID
 * @return {AxiosPromise<{ clientSecret: string, customerId: string }>} - Object containing the Stripe's Client Secret and customerId
 */
module.exports.getWallet = id => {
  return axios.post('/wallet', { id })
}

/**
 * Request to send checkout email
 * @param {string} email - Customer's email
 * @param {string} customer_name - Customer's full name
 * @param {booking[]} cart - Customer's cart
 * @return {AxiosResponse<any>}
 */
module.exports.sendCheckoutEmail = (email, customer_name, cart) => {
  return axios.post('/checkout', {
    email,
    customer_name,
    cart
  })
}

/**
 * Request to send checkout email
 * @param {Object} customer
 * @param {string} customer.id - Customer's ID
 * @param {Object} payment
 * @param {string} payment.id - Stripe payment intent's id
 * @param {booking[]} cart - Customer's cart
 * @return {AxiosResponse<any>}
 */
module.exports.sendCheckoutFailedEmail = (customer, payment, cart) => {
  return axios.post('/checkout', {
    user: customer,
    payment,
    cart
  })
}
