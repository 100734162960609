import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'

const Form = ({
  profile,
  handleProfileValue,
  handleFormSubmit,
  handleEnableMFA,
  verified,
  handleSendVerificationEmail
}) => {
  const [hasPhone, setHasPhone] = useState(!!profile.phoneNumber)
  const phoneNumber = profile.phoneNumber || ''
  const hasMFA = !!profile.mfa
  const setPhoneNumber = value => {
    const e = {
      target: {
        value,
        name: 'phoneNumber'
      }
    }
    setHasPhone(!!e.target.value)
    handleProfileValue(e)
  }
  return (
    <form
      className="mb-lg-5"
      onSubmit={e => {
        handleFormSubmit(e)
      }}
    >
      <div className="form-group">
        <label htmlFor="phoneNumber" className="font-weight-bold">
          Mobile number
        </label>
        <PhoneInput
          placeholder="+61 412 345 678"
          className="form-control shadow-input"
          name="phoneNumber"
          id="phoneNumber"
          defaultCountry="AU"
          international
          withCountryCallingCode
          value={phoneNumber}
          onChange={setPhoneNumber}
          readOnly={hasMFA}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email" className="font-weight-bold">
          Email
        </label>
        <input
          type="email"
          className="form-control shadow-input"
          placeholder="wayne@gmail.com"
          name="email"
          id="email"
          required
          value={profile.email}
          onChange={e => handleProfileValue(e)}
          readOnly={hasMFA}
        />
      </div>
      <div className="form-group">
        <label htmlFor="fullName" className="font-weight-bold">
          Full name
        </label>
        <input
          type="text"
          className="form-control shadow-input"
          placeholder="Wayne Rooney"
          name="fullName"
          id="fullName"
          required
          value={profile.fullName}
          onChange={e => handleProfileValue(e)}
        />
      </div>
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="checkMFA"
          disabled={!hasPhone}
          checked={hasMFA}
          onChange={handleEnableMFA}
        />
        <label className="form-check-label" htmlFor="checkMFA">
          {!hasMFA ? 'Enable' : 'Disable'} MFA
        </label>
      </div>
      {!hasMFA && (
        <div className="form-group mt-5">
          <button type="submit" className="btn btn-primary btn-block btn-lg">
            Update
          </button>
        </div>
      )}
      {!verified && (
        <div className="form-group">
          <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={handleSendVerificationEmail}>
            Verify Email
          </button>
        </div>
      )}
    </form>
  )
}

export default Form
