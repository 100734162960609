import React, { useContext } from 'react'
import List from './list'
import Data from '../common/data'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import userContext from '../../context/userContext'
import { getBuyerBookings } from '../../requests/bookingHttp'

const Inspections = () => {
  const query = queryString.parse(useLocation().search)
  const { profile } = useContext(userContext)
  const error = {
    cta: 'Find my dream home',
    message: 'Looks like you haven’t booked any inspections yet, search our listings to find your dream home'
  }
  const result = {
    properties: [],
    pages: 0
  }
  const ShortListWithData = Data(List, { error, result }, () =>
    getBuyerBookings({ buyerId: profile.id, page: query.page })
  )
  return (
    <div className="mt-lg-5">
      <h1 className="h3 d-none">My Inspections</h1>
      <ShortListWithData />
    </div>
  )
}

export default Inspections
