import React, { useState } from 'react'
import { isLoggedIn } from '../../services/auth'
import { Redirect } from '@reach/router'
import { DASHBOARD, LOGIN } from '../../routes'
import { Link } from 'gatsby'
import useFirebase from '../../hooks/useFirebase'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const app = useFirebase()
  const handleForgotPassword = async e => {
    e.preventDefault()
    setSuccess('')
    setError('')
    try {
      await app.auth().sendPasswordResetEmail(email)
      setSuccess(`A message has been sent to your email`)
    } catch (e) {
      setError(e.message)
    }
  }
  if (isLoggedIn()) return <Redirect to={DASHBOARD} noThrow />
  else
    return (
      <div className="row">
        <div className="col-12 col-lg-6 mx-auto">
          <div>
            <h1 className="font-weight-bold mb-5">
              Forgot your password? Enter you email and we will send you a message
            </h1>
            <div className="my-5">
              <form onSubmit={handleForgotPassword}>
                <div className="form-group">
                  <label htmlFor="email" className="font-weight-bold">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control shadow-input"
                    name="email"
                    id="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="wayne@gmail.com"
                  />
                </div>
                {error && (
                  <div className="alert-danger alert" role="alert">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="alert-success alert" role="alert">
                    {success}
                  </div>
                )}
                <div className="row align-items-center my-4">
                  <div className="col-lg-4 col-12 text-lg-right order-lg-1 mb-4">
                    <button className="btn btn-primary btn-block" type="submit">
                      Send Email
                    </button>
                  </div>
                  <div className="col-lg-8 col-12 order-lg-0 text-center text-lg-left">
                    <p>
                      Return to <Link to={LOGIN}>Login</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
}
export default ForgotPassword
