import React, { useContext } from 'react'
import { formatAddress, getCartFiltered } from './util'
import { addToCart, getUserByEmail } from '../../requests/buyerHttp'
import userContext from '../../context/userContext'
import { useParams } from '@reach/router'
import useSettings from '../../hooks/useSettings'
import $ from 'jquery'

const AddBooking = ({ events, locationAddress, setHasBooking, propertyId, propertyImageURL }) => {
  const { profile, setProfile } = useContext(userContext)
  const { bookingCost } = useSettings()
  const { slug } = useParams()
  let target = null
  const handleBooking = async () => {
    try {
      const { id, email } = profile
      const {
        buyerCollection: { cart }
      } = await getUserByEmail(email)

      target = events.find(item => item.id === propertyId)
      const booking = {
        date: target.date,
        propertyId: target.id,
        address: formatAddress(locationAddress[0]),
        inspectionPrice: bookingCost,
        slug: slug,
        image: propertyImageURL
      }

      const newCart = getCartFiltered(cart, booking)
      await addToCart(id, JSON.stringify(newCart))
      setProfile({ ...profile, cart: newCart })
      setHasBooking(false)
      $('#bookingNotificationModal').modal('show')
    } catch (e) {
      console.error(e.message)
      setHasBooking(true)
    }
  }
  return (
    <button type="button" className="btn btn-primary btn-lg" onClick={handleBooking} id="btnAddBooking">
      Book my Inspection
    </button>
  )
}

export default AddBooking
