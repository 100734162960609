import styles from './property.module.scss'
import Badge from './badge'
import React from 'react'

const Feature = ({ children }) => (
  <div className={styles.detail_feature}>
    <Badge parent="feature">{children}</Badge>
  </div>
)

export default Feature
