import React from 'react'
import Card from './card'
import moment from 'moment'
import { InspectionDate, InspectionTime } from '../../constants'
import Pagination from '../common/pagination'

const today = moment()

const setInspection = item => {
  let dateTime = moment(item.date)
  let date = dateTime.format(InspectionDate)
  let time = dateTime.format(InspectionTime)
  item.inspectionDate = {
    date,
    time
  }
}
const setStatus = item => {
  if (today.diff(item.date, 'days') > 0) {
    item.status = 'viewed'
  }
  if (today.diff(item.date, 'days') === 0) {
    item.status = 'progress'
  }
  if (today.diff(item.date, 'days') < 0) {
    item.status = 'pending'
  }
}

const List = ({ properties }) => {
  const elements = properties.map(({ ...item }) => {
    setInspection(item)
    setStatus(item)
    return item
  })

  return (
    <div>
      {elements.map((inspection, index) => (
        <Card
          inspection={inspection.property}
          day={inspection.inspectionDate}
          lockboxCode={inspection.lockboxCode}
          status={inspection.status}
          key={index}
        />
      ))}
    </div>
  )
}

export default Pagination(List)
