import React, { useContext } from 'react'
import styles from './verify.module.scss'
import Card from './card'
import Empty from '../common/empty'
import useSettings from '../../hooks/useSettings'
import userContext from '../../context/userContext'

const Verify = () => {
  const { verificationCost, chargeVerification } = useSettings()
  const { profile } = useContext(userContext)
  const error = {
    cta: 'Find my dream home',
    message:
      'You haven’t added any homes to your shortlist yet. Search our listings and click the heart symbol to save your favourite homes to your shortlist.'
  }
  return (
    <div className={styles.verify}>
      <p className="my-5 h1">
        <strong>Hi {profile.fullName}!</strong>
      </p>
      <div className="row mb-5">
        <div className="col-12 col-lg-8 mx-auto">
          <p className={`${styles.verify_message} mb-4`}>
            To check you’re a legit human, we need to verify your identity and record your credit card info (don’t worry
            you won’t be charged!) You can complete the verification process now, or find your dream home first and do
            it later.
          </p>
        </div>
        <div className="col-12 col-lg-9 mx-auto">
          <Card goToCheckout={true} verificationCost={verificationCost} chargeVerification={chargeVerification} />
          <div>
            <Empty {...error} fullWidth={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verify
